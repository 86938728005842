// Generated by Framer (6d82f59)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-tv0SL"

const variantClassNames = {Tu6JthOBl: "framer-v-zgjhu8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Tu6JthOBl", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/vQyIUkgphezhY3CoudhcyPXCkA.png", srcSet: "https://framerusercontent.com/images/vQyIUkgphezhY3CoudhcyPXCkA.png?scale-down-to=512 512w,https://framerusercontent.com/images/vQyIUkgphezhY3CoudhcyPXCkA.png 906w"}} className={cx(scopingClassNames, "framer-zgjhu8", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Tu6JthOBl"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tv0SL.framer-3d1wov, .framer-tv0SL .framer-3d1wov { display: block; }", ".framer-tv0SL.framer-zgjhu8 { height: 200px; position: relative; width: 227px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 227
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerH9uAzip_h: React.ComponentType<Props> = withCSS(Component, css, "framer-tv0SL") as typeof Component;
export default FramerH9uAzip_h;

FramerH9uAzip_h.displayName = "Wonder brain";

FramerH9uAzip_h.defaultProps = {height: 200, width: 227};

addFonts(FramerH9uAzip_h, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})